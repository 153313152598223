(function() {
	'use strict';
	angular.module('FYN', [
		'ui.router',
		'satellizer',
		'nemLogging',
		'ngFileUpload',
		'slugifier',
		'angular-sortable-view',
		'toastr',
		'dcbImgFallback',
		'slickCarousel',
		'ngSanitize',
		'wu.masonry',
		'ui.tinymce',
		'ui.bootstrap',
		'720kb.tooltips',
		'swipe',		
		'infinite-scroll',
		'ui.swiper']
	);
	angular.module('FYN').run(function($rootScope, $location, $state, $auth) {
		var protectedStates = [
			'admin',
			'users',
			'tips',
			'locations',
			'lists',
			'lists-new',
			'lists-edit',
			'neighborhoods',
			'neighborhoods-edit',
			'categories',
			'categories-new'
		];
		$rootScope.$on('$stateChangeStart', function(event,next) {
			var e = event;
			protectedStates.forEach(function(v, event) {
				if(v === next.name && next.data.admin) {
					if(!$auth) {
						e.preventDefault();
					}
				}
				if(v === next.name && next.data.superAdminOnly) {
					if($auth.getPayload().role !== 32) {
						e.preventDefault();
						history.back();
					}
				}
			});
			angular.element('html').removeClass('lock');
		})
	})

	angular.module('FYN').directive('updateTitle', ['$rootScope', '$timeout',
		function($rootScope, $timeout) {
			return {
				link: function(scope, element) {
					var listener = function(event, toState) {
						var title = 'Follow Your NOLA - ';
						if (toState.data && toState.data.pageTitle) {
							title += toState.data.pageTitle;
							$timeout(function() {
								element.text(title);
							}, 0, false);
						}
					};
					$rootScope.$on('$stateChangeSuccess', listener);
				}
			};
		}
	]);

	angular.module('FYN').directive('fallbackSrc', function () {
		var fallbackSrc = {
			link: function postLink(scope, iElement, iAttrs) {
				iElement.bind('error', function() {
					angular.element(this).attr("src", iAttrs.fallbackSrc);
				});
			}
		}
		return fallbackSrc;
	});





	// Router
	angular.module('FYN').config(function($stateProvider,$urlRouterProvider,$authProvider,$locationProvider) {

		$stateProvider.state('home', {
			url: '/',
			controller: 'HomeController',
			templateUrl: '../views/home/index.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			},
		});
		$stateProvider.state('home-mobile', {
			url: '/m',
			controller: 'HomeController',
			templateUrl: '../views/mobile/home/index.html',
			resolve: {
				mobile: function() {
					return true;
				}
			},
			data: {
				pageTitle: 'Discover Your New Orleans'
			},
		});



		// MAP STATES

		$stateProvider.state('test-google-maps', {
			url: '/mynola/test',
			controller: 'MapController',
			templateUrl: '../views/map/testlist.html'

		})

		$stateProvider.state('map', {
			url: '/map',
			controller: 'MapController',
			templateUrl: '../views/map/index.html',
			data: {
				pageTitle: 'Map My NOLA'
			}
		});
		$stateProvider.state('map-mobile', {
			url: '/m/map',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/index.html',
			data: {
				pageTitle: 'Map My NOLA'
			}
		});



		$stateProvider.state('map-blank', {
			url: '/map/blank',
			controller: 'MapController',
			templateUrl: '../views/map/blank.html',
			data: {
				pageTitle: 'Map My NOLA'
			}
		});


		$stateProvider.state('stay', {
			url: '/map/stay/:neighborhood/:lodgingtype/:amenities/:name',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Find Places To Stay'
			}
		});
		$stateProvider.state('stay-mobile', {
			url: '/m/map/stay/:neighborhood/:lodgingtype/:amenities/:name',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Find Places To Stay'
			}
		});

		$stateProvider.state('eat', {
			url: '/map/eat/:neighborhood/:cuisinetype/:attire/:pricerange/:when/:name',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Find Restaurants'
			}
		});
		$stateProvider.state('eat-mobile', {
			url: '/m/map/eat/:neighborhood/:cuisinetype/:attire/:pricerange/:when/:name',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Find Restaurants'
			}
		});

		$stateProvider.state('drink', {
			url: '/map/drink/:neighborhood/:features/:name',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: ''
			}
		});
		$stateProvider.state('drink-mobile', {
			url: '/m/map/drink/:neighborhood/:features/:name',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});




		$stateProvider.state('map-form-custom', {
			url: '/m/map/form/custom',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/custom.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});
		$stateProvider.state('map-form-stay', {
			url: '/m/map/form/stay',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/stay.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});
		$stateProvider.state('map-form-eat', {
			url: '/m/map/form/eat',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/eat.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});
		$stateProvider.state('map-form-drink', {
			url: '/m/map/form/drink',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/drink.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});
		$stateProvider.state('map-form-attractions', {
			url: '/m/map/form/attractions',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/attractions.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});
		$stateProvider.state('map-form-shopping', {
			url: '/m/map/form/shopping',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/forms/shopping.html',
			data: {
				pageTitle: 'Here is the title'
			}
		});



		$stateProvider.state('attractions', {
			url: '/map/attractions/:neighborhood/:features/:name',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});

		$stateProvider.state('attractions-mobile', {
			url: '/m/map/attractions/:neighborhood/:features/:name',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});

		$stateProvider.state('shopping', {
			url: '/map/shopping/:neighborhood/:shoppingtypes/:name',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});
		$stateProvider.state('shopping-mobile', {
			url: '/m/map/shopping/:neighborhood/:shoppingtypes/:name',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});

		$stateProvider.state('livemusic', {
			url: '/map/livemusic/:neighborhood',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});
		$stateProvider.state('livemusic-mobile', {
			url: '/m/map/livemusic/:neighborhood',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Discover Your New Orleans'
			}
		});




		$stateProvider.state('tour', {
			url: '/list/tours/:listid/:slug',
			controller: 'MapController',
			templateUrl: '../views/map/tour.html'
		});
		$stateProvider.state('tour-mobile', {
			url: '/m/list/tours/:listid/:slug',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/tour.html'
		});


		$stateProvider.state('list', {
			url: '/list/:category/:listid/:slug',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
		});
		$stateProvider.state('list-mobile', {
			url: '/m/list/:category/:listid/:slug',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
		});




		$stateProvider.state('mynola', {
			url: '/user/:id',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'My NOLA'
			}
		});



		$stateProvider.state('mynola-mobile', {
			url: '/m/user/:id',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'My NOLA'
			}
		});

		$stateProvider.state('map-custom', {
			url: '/map/custom/:tags',
			controller: 'MapController',
			templateUrl: '../views/map/list.html'
		});
		$stateProvider.state('map-custom-mobile', {
			url: '/m/map/custom/:tags',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html'
		});

		$stateProvider.state('search', {
			url: '/search/:term',
			controller: 'MapController',
			templateUrl: '../views/map/list.html'
		});
		$stateProvider.state('search-mobile', {
			url: '/m/search/:term',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html'
		});







		// NEIGHBORHOOD STATES
		$stateProvider.state('allNeighborhoods', {
			url: '/neighborhoods',
			controller: 'NeighborhoodController',
			templateUrl: '../views/neighborhoods/index.html',
			data: {
				pageTitle: 'Explore Neighborhoods'
			}
		});

		$stateProvider.state('allNeighborhoods-mobile', {
			url: '/m/neighborhoods',
			controller: 'NeighborhoodController',
			templateUrl: '../views/mobile/neighborhoods/index.html',
			data: {
				pageTitle: 'Explore Neighborhoods'
			}
		});


		$stateProvider.state('singleNeighborhood', {
			url: '/neighborhoods/:slug',
			controller: 'NeighborhoodController',
			templateUrl: '../views/neighborhoods/single.html',
			data: {
				pageTitle: 'Explore Neighborhoods'
			}
		});

		$stateProvider.state('singleNeighborhood-mobile', {
			url: '/m/neighborhoods/:slug',
			controller: 'NeighborhoodController',
			templateUrl: '../views/mobile/neighborhoods/single.html',
			data: {
				pageTitle: 'Explore Neighborhoods'
			}
		});








		

		// INSPIRATION
		$stateProvider.state('inspiration', {
			url: '/inspiration',
			controller: 'ListController',
			templateUrl: '../views/inspiration/index.html',
			data: {
				pageTitle: 'Get Inspired'
			}
		});


		$stateProvider.state('inspiration-lists', {
			url: '/inspiration/:slug',
			controller: 'ListController',
			templateUrl: '../views/inspiration/lists-by-category.html',
			data: {
				pageTitle: 'Get Inspired'
			}
		});

		$stateProvider.state('inspiration-mobile', {
			url: '/m/inspiration',
			controller: 'InspirationController',
			templateUrl: '../views/mobile/inspiration/index.html',
			data: {
				pageTitle: 'Get Inspired'
			}
		});
/*		$stateProvider.state('inspiration-lists-mobile', {
			url: '/m/inspiration/:slug',
			controller: 'ListController',
			templateUrl: '../views/mobile/inspiration/lists-by-category.html',
			data: {
				pageTitle: 'Get Inspired'
			}
		});

*/

		// TERMS, COPYRIGHT, & PRIVACY POLICY
		$stateProvider.state('copyright', {
			url: '/copyright',
			controller: 'CopyrightController',
			templateUrl: '../views/legal/copyright.html',
			data: {
				pageTitle: 'Follow Your NOLA - Copyright'
			}
		});
		$stateProvider.state('copyright-mobile', {
			url: '/m/copyright',
			controller: 'CopyrightController',
			templateUrl: '../views/mobile/legal/copyright.html',
			data: {
				pageTitle: 'Follow Your NOLA - Copyright'
			}
		});

		$stateProvider.state('privacyPolicy', {
			url: '/privacy-policy',
			controller: 'PrivacyPolicyController',
			templateUrl: '../views/legal/privacy.html',
			data: {
				pageTitle: 'Follow Your NOLA - Privacy Policy'
			}
		});
		$stateProvider.state('privacy-mobile', {
			url: '/m/privacy-policy',
			controller: 'PrivacyPolicyController',
			templateUrl: '../views/mobile/legal/privacy.html',
			data: {
				pageTitle: 'Follow Your NOLA - Privacy Policy'
			}
		});

		$stateProvider.state('termsOfUse', {
			url: '/termsofuse',
			controller: 'TermsOfUseController',
			templateUrl: '../views/legal/terms.html',
			data: {
				pageTitle: 'Follow Your NOLA - Terms of Use'
			}
		});
		$stateProvider.state('terms-mobile', {
			url: '/m/termsofuse',
			controller: 'TermsOfUseController',
			templateUrl: '../views/mobile/legal/terms.html',
			data: {
				pageTitle: 'Follow Your NOLA - Terms of Use'
			}
		});







		// AUTH STATES
		$stateProvider.state('verify', {
			url: '/auth/verify/:username/:id/:token',
			controller: 'AuthController',
			templateUrl: '../views/auth/verify.html'
		});

		$stateProvider.state('passwordresetverify', {
			url: '/auth/password/reset/:email/:verifyid/:verifytoken',
			controller: 'AuthController',
			templateUrl: '../views/home/index.html'
		});

		$stateProvider.state('passwordresetverify-mobile', {
			url: '/m/auth/password/reset/:email/:verifyid/:verifytoken',
			controller: 'AuthController',
			templateUrl: '../views/mobile/home/index.html'
		});



		// ADMIN STATES
		$stateProvider.state('admin', {
			url: '/admin',
			controller: 'AdminController',
			templateUrl: '../views/admin/dashboard/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('users', {
			url: '/admin/users',
			controller: 'AdminController',
			templateUrl: '../views/admin/users/index.html',
			data: {
				admin: true,
				superAdminOnly: true
			}
		});

		$stateProvider.state('tips', {
			url: '/admin/tips',
			controller: 'AdminController',
			templateUrl: '../views/admin/tips/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('locations', {
			url: '/admin/locations',
			controller: 'AdminController',
			templateUrl: '../views/admin/locations/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('tags', {
			url: '/admin/tags',
			controller: 'AdminController',
			templateUrl: '../views/admin/tags/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('lists', {
			url: '/admin/lists',
			controller: 'AdminController',
			templateUrl: '../views/admin/lists/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('lists-new', {
			url: '/admin/lists/new',
			controller: 'AdminController',
			templateUrl: '../views/admin/lists/new.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('lists-edit', {
			url: '/admin/lists/edit/:id',
			controller: 'AdminController',
			templateUrl: '../views/admin/lists/edit.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('categories', {
			url: '/admin/categories',
			controller: 'AdminController',
			templateUrl: '../views/admin/categories/index.html',
			data: {
				admin: true
			}

		});

		$stateProvider.state('categories-new', {
			url: '/admin/categories/new',
			controller: 'AdminController',
			templateUrl: '../views/admin/categories/new.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('neighborhoods', {
			url: '/admin/neighborhoods',
			controller: 'AdminController',
			templateUrl: '../views/admin/neighborhoods/index.html',
			data: {
				admin: true
			}
		});

		$stateProvider.state('neighborhoods-edit', {
			url: '/admin/neighborhoods/edit/:slug',
			controller: 'AdminController',
			templateUrl: '../views/admin/neighborhoods/edit.html',
			data: {
				admin: true
			}
		});


		$stateProvider.state('location', {
			url: '/location/:locationid',
			controller: 'MapController',
			templateUrl: '../views/map/location.html',
			data: {
				pageTitle: ''
			}

		});

		$stateProvider.state('location-mobile', {
			url: '/m/location/:locationid',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/location.html',
			data: {
				pageTitle: ''
			}

		});



		// NOT FOUND
		$stateProvider.state('notFound', {
			url: '/404',
			controller: 'NotFoundController',
			templateUrl: '../views/404/index.html',
			data: {
				pageTitle: 'Follow Your NOLA - Page Not Found'
			}
		});





		$stateProvider.state('olduserlink', {
			url: '/:id',
			controller: 'MapController',
			templateUrl: '../views/map/list.html',
			data: {
				pageTitle: 'Follow Your NOLA - Page Not Found'
			}
		});
		$stateProvider.state('olduserlink-mobile', {
			url: '/m/:id',
			controller: 'MapController',
			templateUrl: '../views/mobile/map/list.html',
			data: {
				pageTitle: 'Follow Your NOLA - Page Not Found'
			}
		});





		if(location.pathname.split('/')[1] === 'm') {
			var oauthRedirectUri = window.location.origin+'/m';
		} else {
			var oauthRedirectUri = window.location.origin;
		}

		// SATELLIZER CONFIGS
		$authProvider.loginUrl = '/api/auth/login';
		$authProvider.signupUrl = '/api/auth/register';


		$authProvider.facebook({
			clientId: '1538162996482235',
			name: 'facebook',
			url: '/api/auth/facebook',
			authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
			redirectUri: oauthRedirectUri+'/',
			requiredUrlParams: ['display', 'scope'],
			scope: ['email'],
			scopeDelimiter: ',',
			display: 'popup',
			type: '2.0',
			popupOptions: { width: 580, height: 400 }
		});

		$authProvider.google({
			clientId: '384108566176-cobrh0tded38ac1fk8sp3uous1elee62.apps.googleusercontent.com',
			url: '/api/auth/google',
			authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
			redirectUri: oauthRedirectUri+'/',
			requiredUrlParams: ['scope'],
			optionalUrlParams: ['display'],
			scope: ['profile', 'email'],
			scopePrefix: 'openid',
			scopeDelimiter: ' ',
			display: 'popup',
			type: '2.0',
			popupOptions: { width: 452, height: 633 }
		});




		$urlRouterProvider.otherwise('/404');
		$locationProvider.html5Mode(true);
	});

	// ALL OTHER CONFIGS

	angular.module('FYN').config(['$httpProvider', function($httpProvider) {
		$httpProvider.defaults.useXDomain = true;
		delete $httpProvider.defaults.headers.common['X-Requested-With'];
		}
	]);

	angular.module('FYN').config(function ($provide) {
		$provide.decorator('$uiViewScroll', function ($delegate) {
			return function (uiViewElement) {
				window.scrollTo(0, (top - 30));
			};
		});
	});

	angular.module('FYN').config(function($logProvider) {
		$logProvider.debugEnabled(false);
	});

	// Toastr Config
	angular.module('FYN').config(function(toastrConfig) {
		angular.extend(toastrConfig, {
		    positionClass: 'toast-top-right',
		    toastClass: 'toast',
		    iconClass: 'toast-blue'
		});
	});

	angular.module('FYN').config(function($sceDelegateProvider) {
		$sceDelegateProvider.resourceUrlWhitelist([
			// Allow same origin resource loads.
			'self',
			// Allow loading from our assets domain.  Notice the difference between * and **.
			'http://jwtauth.app/uploads/**'
		]);
	});


	angular.module('FYN').filter('trustUrl', function ($sce) {
		return function(url) {
			return $sce.trustAsResourceUrl(url);
		};
	});



	angular.module('FYN').config(['$uibTooltipProvider', function ($uibTooltipProvider) {
		//var parser = new UAParser();
		//var result = parser.getResult();
		//var touch = result.device && (result.device.type === 'tablet' || result.device.type === 'mobile');
		if ( Modernizr.touchevents ) {
			$uibTooltipProvider.options({trigger: 'dontTrigger'});
		} else {
			$uibTooltipProvider.options({trigger: 'mouseenter'});
		}
	}]);



	angular.module('FYN').directive('bgImage', function () {
	    return {
	        link: function(scope, element, attr) {

	            attr.$observe('bgImage', function() {           
	              if (!attr.bgImage) {
	                 // No attribute specified, so use default
	                 element.css("background-image","url("+scope.defaultImage+")");
	              } else {
	                 var image = new Image();  
	                 image.src = attr.bgImage;
	                 image.onload = function() { 
	                    //Image loaded- set the background image to it
	                    element.css("background-image","url("+attr.bgImage+")");
	                    element.css('opacity','1')
	                 };
	                 image.onerror = function() {
	                    //Image failed to load- use default
	                    element.css("background-image","url("+scope.defaultImage+")");
	                 };
	             }
	         });
	      }
	    };
	});


	/**
	* First is the Angular Directive method of solving.
	* Second is the inline onerror fix that results in a default image.
	* SOURCE: http://stackoverflow.com/questions/27549134/angularjs-ng-src-condition-if-not-found-via-url
	*/
	
	angular.module('FYN').directive('onErrorSrc', function() {
    return {
        link: function(scope, element, attrs) {
          element.bind('error', function() {
            if (attrs.src != attrs.onErrorSrc) {
              attrs.$set('src', attrs.onErrorSrc);
            }
          });
        }
    }
});	





	angular.module('FYN').directive('fadeIn', function($timeout){
	    return {
	        restrict: 'A',
	        link: function($scope, $element, attrs){
	            $element.addClass("ng-hide-remove");
	            $element.on('load', function() {
	                $element.addClass("ng-hide-add");
	            });
	        }
	    };
	});


/*	angular.module('FYN').config(['$uibTooltipProvider', function ($uibTooltipProvider) {
		$uibTooltipProvider.$get = [
			'$window',
			'$compile',
			'$timeout',
			'$parse',
			'$document',
			'$position',
			'$interpolate',
			function ( $window, $compile, $timeout, $parse, $document, $position, $interpolate ) {
				if ('ontouchstart' in $window) {
					return function () {
						return {
							compile: function () { }
						};
					};
				} else {
					return tooltipFactory($window, $compile, $timeout, $parse, $document, $position, $interpolate);
				}
			}
		];
	}])
*/
})();